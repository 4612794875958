import React from 'react';
import {GatsbyImage} from 'gatsby-plugin-image';

import ButtonLink from './button-link';

import {
  wrapper,
  grid,
  quote as quote_style,
  picture as picture_style,
  name as name_style,
  text,
  moreButton
} from './styles/quotes.module.scss';

type Props = {
  quotes: Array<{
    name: string;
    quote: string;
    picture: any;
  }>;
  detailPage: string;
  detailCta: string;
};

const Quotes = ({quotes, detailPage, detailCta}: Props) => {
  return (
    <div className={wrapper}>
      <div className={grid}>
        {quotes.map(({name, quote, picture}) => (
          <div key={`quote-${name}`} className={quote_style}>
            <GatsbyImage
              image={picture.childImageSharp.gatsbyImageData}
              className={picture_style}
              alt={name}
            />
            <h4 className={name_style}>{name}</h4>
            <p className={text}>{quote}</p>
          </div>
        ))}
      </div>
      <ButtonLink isSecondary className={moreButton} to={detailPage}>
        {detailCta}
      </ButtonLink>
    </div>
  );
};

export default Quotes;
