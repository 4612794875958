import React, { useState } from "react";
import clsx from "clsx";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { useIntersection } from "react-use";
import Vimeo from "@u-wave/react-vimeo";

import Dashes from "../components/dashes";
import Layout from "../components/layout";
import Quotes from "../components/quotes";
import ToolList from "../components/tool-list";
import Trademark from "../components/trademark";
import Faq from "../components/faq";
import Demo from "../components/demo";

import Swoop from "../content/swoop.svg";

import {
  home,
  hero,
  hero_background,
  hero_body,
  hero_content,
  hero_copy,
  hero_video,
  hero_video__floating,
  hero_video_close,
  video_container,
  howItWorks_heading,
  quote,
  attribution,
  attribution_name,
  attribution_title,
} from "./styles/index.module.scss";

const IndexPage = ({
  data: {
    markdownRemark: { frontmatter },
  },
}: any) => {
  const {
    title,
    metaDescription,
    subtitle,
    mission,
    howItWorks,
    tools,
    advisoryBoard,
  } = frontmatter;
  const [isHeroReady, setIsHeroReady] = useState(false);
  const [isHeroPlaying, setIsHeroPlaying] = useState(false);

  const heroRef = React.useRef(null);
  const heroIntersection = useIntersection(heroRef, {});

  const onHeroPlay = () => {
    setIsHeroPlaying(true);
  };

  const onHeroStop = () => {
    setIsHeroPlaying(false);
  };

  return (
    <Layout
      title="Reshaping the art of reading"
      isTitleDisplayed={false}
      metaDescription={metaDescription}
    >
      <div className={home}>
        <div ref={heroRef} className={hero}>
          <div className={hero_background}>
            <Swoop />
          </div>
          <div className={hero_body}>
            <div className={hero_content}>
              <div className={hero_copy}>
                <h1>
                  {title}
                  <Trademark />
                </h1>
                <h2>
                  {subtitle}
                  <Trademark />
                </h2>
                <div className={quote}>
                  <p>{mission.quote}</p>
                  <div className={attribution}>
                    <GatsbyImage
                      image={mission.headshot.childImageSharp.gatsbyImageData}
                      alt={mission.name}
                    />
                    <div className={attribution_name}>
                      <span>{mission.name}</span>
                      <span className={attribution_title}>{mission.title}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={clsx(hero_video, {
                  [hero_video__floating]:
                    isHeroReady &&
                    isHeroPlaying &&
                    !heroIntersection?.isIntersecting,
                })}
              >
                <div className={video_container}>
                  <button
                    type="button"
                    className={hero_video_close}
                    onClick={onHeroStop}
                  >
                    ×
                  </button>
                  <Vimeo
                    video={531_474_782}
                    showByline={false}
                    showPortrait={false}
                    showTitle={false}
                    responsive
                    onReady={() => {
                      setIsHeroReady(true);
                    }}
                    onPlay={onHeroPlay}
                    onEnd={onHeroStop}
                    onError={onHeroStop}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <h3 id="howItWorks" className={howItWorks_heading}>
          {howItWorks.heading}
        </h3>
        <Demo text={howItWorks.body} />
        <h3>{tools.heading}</h3>
        <ToolList tools={tools.toolList} learnMoreLabel={tools.learnMore} />
        <Dashes
          height={500}
          path="M1 502c30-143 133-175 309-100 177 76 276 29 297-139 15-145-13-233-85-262"
          style={{
            position: "absolute",
            left: 0,
            right: 0,
            margin: "-4em auto 1em",
          }}
          width={612}
        />
        <h3 style={{ marginTop: "2em" }}>{advisoryBoard.name}</h3>
        <div className={video_container} style={{ marginBottom: "4em" }}>
          <Vimeo
            video={516_475_947}
            showByline={false}
            showPortrait={false}
            showTitle={false}
            responsive
          />
        </div>
        <Quotes
          quotes={advisoryBoard.quotes}
          detailPage="/advisoryBoard/"
          detailCta={advisoryBoard.cta}
        />
        <Faq />
      </div>
    </Layout>
  );
};

// eslint-disable-next-line @typescript-eslint/no-confusing-void-expression
export const pageQuery = graphql`
  query ($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      frontmatter {
        path
        title
        metaDescription
        subtitle
        logo {
          relativePath
        }
        mission {
          heading
          quote
          name
          title
          headshot {
            childImageSharp {
              gatsbyImageData(width: 50, height: 50, layout: FIXED)
            }
          }
        }
        howItWorks {
          heading
          body
        }
        tools {
          heading
          body
          learnMore
          toolList {
            name
            description
            url
            screenshot {
              childImageSharp {
                gatsbyImageData(width: 450, layout: CONSTRAINED)
              }
            }
            marginIcon
          }
        }
        advisoryBoard {
          name
          cta
          quotes {
            name
            quote
            picture {
              childImageSharp {
                gatsbyImageData(
                  width: 200
                  height: 200
                  transformOptions: { grayscale: true }
                  layout: CONSTRAINED
                )
              }
            }
          }
        }
      }
    }
  }
`;

export default IndexPage;
