import React, {useRef} from 'react';
import Scrollbar from 'react-scrollbars-custom';

import Toolbar from './toolbar';
import Paragraph from './paragraph';
import usePrintArcText from '../hooks/use-print-arc-text';
import useSelectWordOnClick from '../hooks/use-select-word-on-click';

import {
  demo,
  demo_content,
  demo_text,
  demo_callout
} from './styles/demo.module.scss';

const Demo = ({text}: {text: string}) => {
  const textRef = useRef<HTMLDivElement>(null);

  const {doPrint, isPrintLoading} = usePrintArcText();

  useSelectWordOnClick(textRef);

  return (
    <div className={demo}>
      <div className={demo_content}>
        <Scrollbar disableTracksWidthCompensation>
          <div ref={textRef} className={demo_text}>
            <Paragraph>{text}</Paragraph>
          </div>
        </Scrollbar>
      </div>
      <Toolbar
        maxWidth={1000}
        isPrintLoading={isPrintLoading}
        onPrint={() => {
          doPrint(text);
        }}
      />
      <div className={demo_callout}>Try it out!</div>
    </div>
  );
};

export default Demo;
