import React from 'react';
import clsx from 'clsx';
import {GatsbyImage} from 'gatsby-plugin-image';
import {Link} from 'gatsby';

import Glasses from '../content/icons/glasses.svg';
import Laptop from '../content/icons/laptop.svg';
import Pencil from '../content/icons/pencil.svg';
import Rocket from '../content/icons/rocket.svg';
import ButtonLink from './button-link';
import Dashes from './dashes';

import {
  tools as tools_style,
  tool,
  toolBody,
  screenshot as screenshot_style,
  description as description_style,
  marginIcon as marginIcon_style,
  right,
  dashes
} from './styles/tool-list.module.scss';

const Icons = {Glasses, Laptop, Pencil, Rocket};

type Props = {
  tools: Array<{
    name: string;
    description: string;
    url: string;
    screenshot: any;
    marginIcon: keyof typeof Icons;
  }>;
  learnMoreLabel: string;
};

const ToolList = ({tools, learnMoreLabel}: Props) => (
  <div className={tools_style}>
    {tools.map(({name, description, url, screenshot, marginIcon}, i) => {
      if (!(marginIcon in Icons)) {
        return;
      }

      const MarginIcon = Icons[marginIcon];
      return (
        <div key={`tool-${name}`} className={tool}>
          <h4>{name}</h4>
          <MarginIcon
            className={clsx(marginIcon_style, {[right]: i % 2})}
            style={{width: '64px'}}
          />
          <div className={toolBody}>
            <Link
              to={url}
              className={screenshot_style}
              aria-label={`${learnMoreLabel} about ${name}`}
            >
              <GatsbyImage
                image={screenshot.childImageSharp.gatsbyImageData}
                alt={`Screenshot of ${name}`}
              />
            </Link>
            <div className={description_style}>
              <p>{description}</p>
              <ButtonLink isSecondary to={url}>
                {learnMoreLabel} about {name}
              </ButtonLink>
            </div>
          </div>
          <Dashes
            className={dashes}
            width={452}
            height={260}
            path="M1 0c0 74 76 111 227 111s227 50 227 149"
          />
        </div>
      );
    })}
  </div>
);

export default ToolList;
