import React from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel
} from '@reach/accordion';
import {graphql, useStaticQuery} from 'gatsby';

import {item, question, answer} from './styles/faq.module.scss';

// eslint-disable-next-line @typescript-eslint/no-confusing-void-expression
const FAQ_QUERY = graphql`
  query FAQ {
    markdownRemark(fileAbsolutePath: {regex: "/.*/faq.md/"}) {
      frontmatter {
        faqs {
          answer
          question
        }
      }
    }
  }
`;

const Item = ({
  questionText,
  answerText
}: {
  questionText: string;
  answerText: string;
}) => (
  <AccordionItem
    itemScope
    className={item}
    itemProp="mainEntity"
    itemType="https://schema.org/Question"
  >
    <h4 className={question} itemProp="name">
      <AccordionButton>{questionText}</AccordionButton>
    </h4>
    <AccordionPanel
      itemScope
      className={answer}
      itemProp="acceptedAnswer"
      itemType="https://schema.org/Answer"
    >
      <p itemProp="text">{answerText}</p>
    </AccordionPanel>
  </AccordionItem>
);

const Faq = () => {
  const {
    markdownRemark: {
      frontmatter: {faqs}
    }
  } = useStaticQuery<{
    markdownRemark: {
      frontmatter: {
        faqs: [
          {
            question: string;
            answer: string;
          }
        ];
      };
    };
  }>(FAQ_QUERY);
  return (
    <>
      <h3>Frequently asked questions</h3>
      <Accordion collapsible itemScope itemType="https://schema.org/FAQPage">
        {faqs.map(({question, answer}) => (
          <Item key={question} questionText={question} answerText={answer}/>
        ))}
      </Accordion>
    </>
  );
};

export default Faq;
