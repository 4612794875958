import React from 'react';

const Trademark = ({...props}) => (
  <span
    style={{
      fontSize: '18px',
      verticalAlign: 'top',
      alignSelf: 'normal',
      display: 'inline-block',
      width: 0
    }}
    {...props}
  >
    ™
  </span>
);

export default Trademark;
